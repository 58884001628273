import React, { useEffect } from 'react'
import Section from '../components/Section'
import Layout from '../components/Layout'
import styled from 'styled-components'
import queryString from 'query-string-es5'
import MoreInfo from '../../../src/containers/MoreInfoForm'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
const postForm = {}
const lawyerType = ''

const MoreInfoWrapper = styled.div`
    width: 60%;
    margin: 20px auto;
    > button {
        margin-top: 45px;
        margin-left: 30px;
    }
    @media (max-width: 768px) {
        width: 100%;
        > button {
            margin-left: 0;
        }
    }
`

const Confirmation = ({ location }) => {
    const { lawyerType, uuid, hideMoreInfo } = queryString.parse(
        location.search
    )
    const { i18n } = useTranslation()
    useEffect(() => {
        if (
            typeof window !== 'undefined' &&
            typeof window.gtag !== 'undefined'
        ) {
            const value =
                location.pathname.indexOf('/confirmation-mp') === 0
                    ? 1777.0
                    : 0.01777
            window.gtag('event', 'conversion', {
                send_to: 'AW-624237663/E42ECMykutUDEN-41KkC',
                transaction_id: uuid,
                value: value,
                currency: 'CAD',
            })
        }
    })

    return (
        <Layout location={location} noPhones={true}>
            <Section paddingTop="0">
                <h1>CONFIRMATION</h1>
                <p>
                    <Trans i18nKey="confirmation.received">
                        Nous vous confirmons que nous avons{' '}
                        <strong>bien reçu votre demande</strong>. Nous vous
                        trouverons un avocat compétent qui pourra vous aider à
                        régler votre dossier efficacement!
                    </Trans>
                </p>

                {hideMoreInfo === 'true' ? null : (
                    <MoreInfoWrapper>
                        <MoreInfo
                            lawyerType={lawyerType}
                            uuid={uuid}
                            skip={i18n.language === 'en'}
                        />
                    </MoreInfoWrapper>
                )}

                <p>
                    <Trans i18nKey="confirmation.email">
                        Vous recevrez la confirmation de votre demande par
                        courriel dans les prochaines minutes. <br />
                        <strong>Vous êtes pris en charge!</strong>
                    </Trans>
                </p>
                <h2>
                    <Trans i18nKey="confirmation.whatAreNextSteps">
                        Quelles sont les prochaines étapes?
                    </Trans>
                </h2>
                <p>
                    <Trans i18nKey="confirmation.nextSteps">
                        Nous sélectionnons l’avocat qui répond le mieux à vos
                        besoins. <br />
                        <br /> Dans le cas où l’information fournie ne nous
                        permettrait pas d’identifier le bon avocat pour vous,
                        notre équipe service clients et conseillers en réussite
                        vous contacterons pour clarifier votre demande.
                    </Trans>
                </p>
                <h2>
                    <Trans i18nKey="confirmation.howDoesItWork">
                        Comment ça fonctionne?
                    </Trans>
                </h2>
                <p>
                    <Trans i18nKey="confirmation.howItWorks">
                        L’avocat vous contactera rapidement pour discuter de vos
                        besoins, répondre à toutes vos questions et vous offrir
                        ses services. Vous serez ensuite libre de décider de
                        poursuivre ou non votre démarche avec lui.
                    </Trans>
                </p>
            </Section>
        </Layout>
    )
}

export default Confirmation

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
